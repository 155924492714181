import { Injectable } from '@angular/core';
import {
  BackendService,
  BaseApiService,
  RequestFacadeModel,
  RequestModel,
  RequestType,
} from '@cf/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IPaymentList, IStripeCurrency } from './interfaces';
import { PaymentQuery, PaymentStore } from './store';

@Injectable({
  providedIn: 'root',
})
export class PaymentStoreService extends BaseApiService {
  public broadcastChannel = new BroadcastChannel('STRIPE_HANDLER');

  payments$: Observable<IPaymentList[]> = this.paymentQuery.payments$;

  constructor(
    private readonly paymentStore: PaymentStore,
    private readonly paymentQuery: PaymentQuery,
    backendService: BackendService,
  ) {
    super(backendService, 'connections');
  }

  get paymentList(): IPaymentList[] {
    return this.paymentQuery.getValue().data;
  }

  get defaultPayment() {
    return this.paymentList.find((p) => p.is_default);
  }

  public getPaymentList(): void {
    this.getPayments$().subscribe();
  }

  public reset(): void {
    this.paymentStore.reset();
  }

  stripeUrl(target: '_self' | '_blank' = '_self') {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('stripe/auth-url'),
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send<{ auth_uri: string }>(requestFacade).subscribe((res: { auth_uri: string }) => {
      window.open(res.auth_uri, target);
    });
  }

  connectStripe(code: string) {
    const request: RequestModel<{ code: string }> = new RequestModel({
      url: this.getFullUrl('stripe'),
      requestBody: { code },
      skipNotify: true,
    });
    const requestFacade: RequestFacadeModel<{ code: string }> = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });
    return this.send(requestFacade);
  }

  setDefaultStripe(id: number) {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(`stripe/${id}/set-default`),
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });
    return this.send(requestFacade);
  }

  disconnectStripe(id: number) {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('stripe'),
    }).withID(id);
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.delete,
      request,
    });
    return this.send(requestFacade);
  }

  stripeCurrencies(country: string): Observable<IStripeCurrency[]> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('currencies', 'stripe'),
    }).withQuery({ country });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send(requestFacade);
  }

  private getPayments$(): Observable<IPaymentList[]> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('stripe'),
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send<IPaymentList[]>(requestFacade).pipe(
      tap((payments: IPaymentList[]) => {
        this.paymentStore.update({ data: payments });
      }),
    );
  }

  // get firstCalendar(): ICalendar | undefined {
  //   return this.calendars.find(Boolean);
  // }
}
